<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学员档案</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="学员姓名" class="searchboxItem ci-full">
              <span class="itemLabel">学员姓名:</span>
              <el-input
                class="listInput"
                v-model="userName"
                placeholder="请输入学员姓名"
                size="small"
                clearable
              />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input
                class="listInput"
                v-model="idcard"
                placeholder="请输入身份证号"
                size="small"
                clearable
              />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input
                class="listInput"
                v-model="mobile"
                placeholder="请输入手机号"
                size="small"
                clearable
              />
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
            <el-button
              type="primary"
              class="bgc-bv"
              round
              @click="showDocsDialog()"
              >批量下载培训档案
            </el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="学员姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
                minWidth="180"
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
                prop="idcard"
                minWidth="180"
              />
              <el-table-column
                label="手机号"
                align="left"
                show-overflow-tooltip
                prop="mobile"
                minWidth="180"
              />
              <el-table-column
                label="操作"
                align="center"
                width="300px"
                fixed="right"
              >
                <el-popover
                  placement="right"
                  width="170"
                  trigger="click"
                  slot-scope="scope"
                >
                  <el-select
                    v-model="yearList"
                    @change="pdfview()"
                    placeholder="请选择档案年份"
                    size="small"
                    no-data-text="暂无档案生成"
                  >
                    <el-option
                      v-for="item in groupform.yearListoptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      <span>{{ item }}</span>
                    </el-option>
                  </el-select>
                  <el-button slot="reference" @click="open(scope.row)" size="small"
                    >电子档案预览</el-button
                  >
                </el-popover>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="电子档案批量下载"
      :visible.sync="docsDialogVisible"
      width="50%"
      top="5%"
      :center="true"
      :before-close="closeDocsDialog"
    >
      <el-form
        ref="groupform"
        :model="groupform"
        style="width: 100%"
        prop="groupform"
      >
        <div
          :style="trainTypeId == 11 ? 'height:520px' : ''"
          style="text-align: center; margin: 0 auto"
        >
          <div
            style="
              width: 480px;
              margin: 0 auto;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <el-select
              v-model="groupform.yearnoidList"
              aria-placeholder="请选择档案年份"
              size="small"
              class="times"
            >
              <el-option
                v-for="item in groupform.yearnoidListoptions"
                :key="item"
                :label="item + '年'"
                :value="item + '年'"
              >
              </el-option>
            </el-select>
            <span style="display: inline-block; font-size: 18px"
              >学员档案批量下载</span
            >
          </div>
          <el-divider></el-divider>
          <h2 style="text-align: left; padding-bottom: 15px">筛选条件</h2>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <el-form-item
              label="班级名称："
              label-width="100px"
              prop="projectName"
              style="width: 315px"
            >
              <el-input
                v-model="groupform.projectName"
                size="small"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item
              label="班级编号："
              label-width="100px"
              prop="projectCode"
              style="width: 315px"
            >
              <el-input
                v-model="groupform.projectCode"
                size="small"
                show-word-limit
              ></el-input>
            </el-form-item>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <el-form-item
              label="领券时间："
              label-width="100px"
              prop="shanxiUserStart"
            >
              <el-date-picker
                v-model="groupform.shanxiUserStart"
                type="date"
                size="small"
                value-format="yyyy-MM-dd"
                placeholder="选择领券时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="截止时间："
              label-width="100px"
              prop="shanxiUserEnd"
            >
              <el-date-picker
                v-model="groupform.shanxiUserEnd"
                type="date"
                size="small"
                value-format="yyyy-MM-dd"
                placeholder="选择截止时间"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <div>
          <el-button @click="closeDocsDialog">取消</el-button>
          <el-button class="bgc-bv" @click="confirmDocsDialog"
            >下载导出</el-button
          >
        </div>
      </span>
    </el-dialog>
    <el-dialog
      title="电子档案"
      :visible.sync="dialogCert"
      width="50%"
      top="2%"
      center
      :before-close="closeDownloadCert"
    >
      <div id="pdf-cert" style="height: 600px"></div>
    </el-dialog>
  </div>
</template>
<script>
const className = "eduCertService";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
export default {
  name: "trainingManagement/shanxiTrainingRecords",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      docsDialogVisible: false,
      userName: "",
      idcard: "",
      mobile: "",
      classStatusList: [], //
      areatreeList: [], //行政区域list
      companyList: [], //开班单位列表

      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      params: {},
      ruleForm: {
        Trainingtype: "",
      },
      yearList: "",
      groupform: {
        yearnoidListoptions: [], //年份
        yearnoidList: new Date().getFullYear() + "年",
        yearListoptions: [],
        projectName: "", //班级名称
        projectCode: "", //班级编码
        shanxiUserStart: "",
        shanxiUserEnd: "",
      },
      dialogCert: false,
    };
  },
  created() {},
  watch: {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.init();
    this.getTableHeight();
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    this.beforeunloadHandler();
  },
  methods: {
    pdfview() {
      this.$post("/student/profile/shanxi/show/pdf", {
        userId: this.userId,
        year: this.yearList,
      })
        .then((ret) => {
          this.dialogCert = true;
          this.$nextTick(() => {
            pdf.embed(ret.data, "#pdf-cert");
          });
        })
        .catch((err) => {
          return;
        });
    },
    beforeunloadHandler(e) {
      e = e || window.event;
      if (e) {
        console.log(e);
        e.returnValue = "关闭提示";
      }
      this.getData(-1);
    },
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    getLearningstateList() {
      const classStatusList = this.$setDictionary("PROJECTSTATE", "list");
      const list = [];
      list.push({
        value: "",
        label: "全部",
      });
      for (const key in classStatusList) {
        if (key == "" || key == "50" || key == "60") {
          list.push({
            value: key,
            label: classStatusList[key],
          });
        }
      }
      this.classStatusList = list;
    },
    init() {
      this.getareatree();
      this.getTableHeight();
      this.getData(-1);
      this.getLearningstateList();
      this.getCompanyList();
    },
    // 资源提供者
    getCompanyList(query) {
      if (query && query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
    // 获取档案年份带id
    getyearList(userId) {
      this.$post("/student/profile/shanxi/list/year", { userId: userId })
        .then((res) => {
          if (res.status == 0) {
            this.groupform.yearListoptions = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    // 获取档案年份不带id
    getyearListnoId() {
      this.$post("/student/profile/shanxi/list/year", {})
        .then((res) => {
          if (res.status == 0) {
            this.groupform.yearnoidListoptions = res.data || [];
            this.groupform.yearnoidList = new Date().getFullYear() + "年";
          }
        })
        .catch(() => {
          return;
        });
    },
    //跳转
    open(row) {
      this.userId = row.userId;
      this.yearList = undefined;
      this.getyearList(row.userId);
    },
    //  取消下载
    closeDocsDialog() {
      this.groupform.yearnoidList = "";
      if (this.$refs["groupform"] != undefined) {
        this.$refs["groupform"].resetFields();
      }
      this.docsDialogVisible = false;
    },
    // 下载培训档案
    showDocsDialog() {
      this.$nextTick(() => {
        if (this.$refs["groupform"] != undefined) {
          this.$refs["groupform"].resetFields();
        }
      });
      this.getyearListnoId();
      this.docsDialogVisible = true;
    },
    // 下载档案
    confirmDocsDialog() {
      if (!this.groupform.yearnoidList) {
        this.$message({
          type: "warning",
          message: "请先选择年份下载学员档案！",
        });
        return;
      }
      let params = {
        fileDTOS: [],
      };
      params.fileDTOS.push({
        fileType: "125",
        fileName: this.groupform.yearnoidList + "-学员档案",
        className: "eduProjectUserService",
        param: {
          year: this.groupform.yearnoidList.substring(0, 4),
          // projectId: this.groupform.projectId,
          projectCode: this.groupform.projectCode,
          projectName: this.groupform.projectName,
          shanxiUserStart: this.groupform.shanxiUserStart,
          shanxiUserEnd: this.groupform.shanxiUserEnd,
        },
      });
      this.$post("/sys/download/asyncDownloadFile", params).then((re) => {
        if (re.status == "0") {
          let list = re.data;
          for (let item of list) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
          this.closeDocsDialog();
          if (this.$refs["groupform"] != undefined) {
            this.$refs["groupform"].resetFields();
          }
        } else {
          this.$message.error(re.message);
        }
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageSize: this.pageSize,
      };
      if (this.idcard) {
        params.idcard = this.idcard;
      }
      if (this.mobile) {
        params.mobile = this.mobile;
      }
      if (this.userName) {
        params.userName = this.userName;
      }
      this.doFetch({
        url: "/student/profile/shanxi/page/user",
        params,
        pageNum,
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data || [];
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scoped>
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}

.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}

.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.times {
  /deep/.el-input__inner {
    border: none;
    width: 110px;
    font-size: 18px;
  }
  /deep/.el-select__caret {
    font-size: 17px;
  }
}
</style>
